import { createStore } from "vuex";
import system from "./system.store";
import user from "./user.store";
import courses from "./courses.store";
import course from "./course.store";
import teacher from "./teacher.store";
import test from "./test.store";
import webinar from "./webinar.store";
import meeting from "./meeting.store";

export default createStore({
  modules: {
    system,
    user,
    courses,
    course,
    teacher,
    test,
    webinar,
    meeting,
  },
});
