import API from "@/api";
import {
  getDataTimeString,
  getPeerUUID,
  loadDevice,
} from "@/package/helpers/webinar";
import { socket } from "@/socket";

const state = {
  meeting: null,
  roomInfo: null,

  state: "init",

  // camera
  isCameraAllowed: false,
  isEnumerateCameraDevices: false,
  cameraDevices: [],
  selectedCameraDevice: null,
  cameraStream: null,
  cameraStartAdmin: false,

  // audio
  isAudioAllowed: false,
  isEnumerateAudioDevices: false,
  audioDevices: [],
  selectedAudioDevice: null,
  audioStream: null,
  audioStartAdmin: false,

  audioOutputDevices: [],
  selectedAudioOutputDevice: null,

  // screenBroadcast
  isScreenBroadcastingAllowed: false,
  screenStream: null,

  participantsCounts: 0,
  participants: [],
  messages: [],

  token: null,

  device: null,
  producerTransport: null,

  producers: new Map(),
  producerLabel: new Map(),

  consumerTransport: null,

  producersList: [],

  fullScreenCard: "",
  userName: "",

  showMicrophoneModal: false,
  showCameraModal: false,
};

const mutations = {
  SET_STATE(store, payload) {
    store[payload.state] = payload.value;
  },

  SET_DEVICES(store, payload) {
    store[payload.state].push(payload.value);
  },

  SET_MEETING_PARTICIPANTS(store, payload) {
    store.participants = JSON.parse(payload);
    store.participantsCounts = store.participants.length;
  },

  SET_MEETING_MESSAGE(store, payload) {
    let data = payload;
    const date = new Date();
    data.time = `${date.getHours()}:${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }`;
    store.messages.unshift(data);
  },

  UPDATE_ROOM_SETTINGS(store, payload) {
    store.roomInfo.moderator[payload.type] = payload.status;
  },

  CLEAR_MEETING(store) {
    store.meeting = null;
    store.roomInfo = null;
    store.state = "init";
    store.isCameraAllowed = false;
    store.isEnumerateCameraDevices = false;
    store.cameraDevices = [];
    store.selectedCameraDevice = null;
    store.cameraStream = null;
    store.isAudioAllowed = false;
    store.isEnumerateAudioDevices = false;
    store.audioDevices = [];
    store.selectedAudioDevice = null;
    store.audioStream = null;
    store.audioOutputDevices = [];
    store.selectedAudioOutputDevice = null;
    store.isScreenBroadcastingAllowed = false;
    store.screenStream = null;
    store.participantsCounts = 0;
    store.participants = [];
    store.messages = [];
    store.token = null;
    store.device = null;
    store.producerTransport = null;
    store.producers = new Map();
    store.producerLabel = new Map();
    store.consumerTransport = null;
    store.producersList = [];
    store.fullScreenCard = "";
    store.userName = "";
    store.showMicrophoneModal = false;
    store.showCameraModal = false;
  },

  CLEAR_MEETING_ON_EXIT(store) {
    store.participantsCounts = 0;
    store.participants = [];
    store.token = null;
    store.device = null;
    store.producerTransport = null;
    store.producers = new Map();
    store.producerLabel = new Map();
    store.consumerTransport = null;
    store.producersList = [];
  },

  UPDATE_PEER_INFO(store, payload) {
    if (payload.type === "audio") {
      store.participants.find(
        (item) => item[1].peer_uuid === payload.peer_id
      )[1].peer_audio = payload.status;
    }

    if (payload.type === "video") {
      store.participants.find(
        (item) => item[1].peer_uuid === payload.peer_id
      )[1].peer_video = payload.status;
    }
  },

  ADD_MEET_PRODUCER(store, payload) {
    store.producersList.push(payload);
  },

  DELETE_MEET_PRODUCER(store, payload) {
    store.producersList = store.producersList.filter(
      (item) => item.consumerId !== payload.consumer_id
    );
  },
};

const actions = {
  loadMeetingInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/webinars/${data}`)
        .then((response) => {
          commit("SET_STATE", { state: "meeting", value: response.data.data });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadMeetingToken({ commit }, data) {
    commit("SET_STATE", { state: "state", value: "join" });
    return new Promise((resolve, reject) => {
      API.get(`api/users/webinars/${data.roomId}/token`)
        .then((response) => {
          commit("SET_STATE", { state: "token", value: response.data.token });
          commit("SET_STATE", { state: "userName", value: data.name });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async initEnumerateCameraDevices({ dispatch, commit }) {
    await navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(async (stream) => {
        await dispatch("enumerateCameraDevices", stream);
        commit("SET_STATE", { state: "isCameraAllowed", value: true });
      })
      .catch(() => {
        commit("SET_STATE", { state: "isCameraAllowed", value: false });
      });
  },

  async enumerateCameraDevices({ commit, dispatch, state }, stream) {
    await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach(async (device) => {
          if (device.kind === "videoinput") {
            commit("SET_DEVICES", { state: "cameraDevices", value: device });
          }
        });

        if (state.cameraDevices.length) {
          commit("SET_STATE", {
            state: "selectedCameraDevice",
            value: state.cameraDevices[0].deviceId,
          });
        } else {
          commit("SET_STATE", { state: "isCameraAllowed", value: false });
        }
      })
      .then(async () => {
        await dispatch("stopTracks", stream);
        commit("SET_STATE", {
          state: "isEnumerateCameraDevices",
          value: true,
        });
      });
  },

  async initEnumerateAudioDevices({ dispatch, commit }) {
    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async (stream) => {
        await dispatch("enumerateAudioDevices", stream);
        commit("SET_STATE", { state: "isAudioAllowed", value: true });
      })
      .catch(() => {
        commit("SET_STATE", { state: "isAudioAllowed", value: false });
      });
  },

  async enumerateAudioDevices({ commit, dispatch, state }, stream) {
    await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach(async (device) => {
          if (device.kind === "audioinput") {
            commit("SET_DEVICES", { state: "audioDevices", value: device });
          }

          if (
            !/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(
              navigator.userAgent || ""
            )
          ) {
            if (device.kind === "audiooutput") {
              commit("SET_DEVICES", {
                state: "audioOutputDevices",
                value: device,
              });
            }
          }
        });

        if (state.audioDevices.length) {
          commit("SET_STATE", {
            state: "selectedAudioDevice",
            value: state.audioDevices[0].deviceId,
          });
        } else {
          commit("SET_STATE", { state: "isAudioAllowed", value: false });
        }

        if (state.audioOutputDevices.length) {
          commit("SET_STATE", {
            state: "selectedAudioOutputDevice",
            value: state.audioOutputDevices[0].deviceId,
          });
        }
      })
      .then(async () => {
        await dispatch("stopTracks", stream);
        commit("SET_STATE", { state: "isEnumerateAudioDevices", value: true });
      });
  },

  async stopTracks(_, stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  },

  handleVideo({ commit, state, dispatch }) {
    if (state.isCameraAllowed) {
      if (state.producerLabel.get("videoType")) {
        dispatch("closeProducer", "videoType");
        commit("SET_STATE", { state: "cameraStartAdmin", value: false });
        socket.emit(
          "updatePeerInfo",
          {
            room_id: state.roomInfo.id,
            peer_name: state.userName,
            peer_id: getPeerUUID(),
            type: "video",
            status: false,
            broadcast: true,
          },
          () => {}
        );
      }
      dispatch("stopTracks", state.cameraStream);
    } else {
      dispatch("changeCamera");
    }

    commit("SET_STATE", {
      state: "isCameraAllowed",
      value: !state.isCameraAllowed,
    });
  },

  async handleAudio({ commit, state, dispatch }) {
    if (state.state === "room") {
      if (state.isAudioAllowed) {
        if (state.producerLabel.get("audioType")) {
          dispatch("closeProducer", "audioType");
          commit("SET_STATE", { state: "audioStartAdmin", value: false });
          socket.emit(
            "updatePeerInfo",
            {
              room_id: state.roomInfo.id,
              peer_name: state.userName,
              peer_id: getPeerUUID(),
              type: "audio",
              status: false,
              broadcast: true,
            },
            () => {}
          );
        }
      } else {
        await dispatch("produce", {
          type: "audioType",
          device: state.selectedAudioDevice,
        });

        socket.emit(
          "updatePeerInfo",
          {
            room_id: state.roomInfo.id,
            peer_name: state.userName,
            peer_id: getPeerUUID(),
            type: "audio",
            status: true,
            broadcast: true,
          },
          () => {}
        );
      }
    }

    commit("SET_STATE", {
      state: "isAudioAllowed",
      value: !state.isAudioAllowed,
    });
  },

  async changeCamera({ commit, dispatch, state }, deviceId) {
    if (state.cameraStream) {
      await dispatch("stopTracks", state.cameraStream);
    }

    const videoConstraints = {
      audio: false,
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        deviceId: deviceId,
        aspectRatio: 1.777,
      },
    };
    await navigator.mediaDevices
      .getUserMedia(videoConstraints)
      .then((camStream) => {
        commit("SET_STATE", {
          state: "cameraStream",
          value: camStream,
        });
      });

    if (state.producerTransport) {
      await dispatch("produce", {
        type: "videoType",
        device: state.selectedCameraDevice,
      });
    }
  },

  async changeStreamCamera({ commit, dispatch, state }, deviceId) {
    if (state.cameraStream) {
      await dispatch("stopTracks", state.cameraStream);
    }

    const videoConstraints = {
      audio: false,
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        deviceId: deviceId,
        aspectRatio: 1.777,
      },
    };
    await navigator.mediaDevices
      .getUserMedia(videoConstraints)
      .then((camStream) => {
        commit("SET_STATE", {
          state: "cameraStream",
          value: camStream,
        });
      });

    if (state.producerTransport) {
      await dispatch("closeProducer", "videoType");
      await dispatch(
        "produce",
        {
          type: "videoType",
          device: state.selectedCameraDevice,
        },
        100
      );
    }
  },

  async changeStreamAudio({ commit, dispatch, state }, deviceId) {
    commit("SET_STATE", { state: "selectedAudioDevice", value: deviceId });

    if (state.isAudioAllowed) {
      await dispatch("closeProducer", "audioType");
      await dispatch(
        "produce",
        {
          type: "audioType",
          device: state.selectedAudioDevice,
        },
        100
      );
    }
  },

  async startScreenSharing({ commit, dispatch }) {
    await navigator.mediaDevices
      .getDisplayMedia({ audio: true, video: { frameRate: { ideal: 30 } } })
      .then((screenStream) => {
        commit("SET_STATE", {
          state: "isScreenBroadcastingAllowed",
          value: true,
        });
        commit("SET_STATE", {
          state: "screenStream",
          value: screenStream,
        });

        dispatch("produce", { type: "screenType" });
      })
      .catch(() => {});
  },

  async stopScreenSharing({ commit, dispatch }) {
    commit("SET_STATE", { state: "isScreenBroadcastingAllowed", value: false });
    commit("SET_STATE", { state: "screenStream", value: null });

    if (state.producerLabel.get("screenType")) {
      dispatch("closeProducer", "screenType");
      dispatch("closeProducer", "audioTab");
    }
  },

  async initDevices({ dispatch, commit }) {
    await dispatch("initEnumerateCameraDevices");
    await dispatch("initEnumerateAudioDevices");
    commit("SET_STATE", { state: "state", value: "settings" });
  },

  joinRoom({ commit, dispatch, state }) {
    socket.emit(
      "createRoom",
      {
        room_id: state.meeting.external_id,
      },
      () => {
        socket.emit(
          "join",
          {
            room_id: state.meeting.external_id,
            peer_info: {
              join_data_time: getDataTimeString(),
              peer_uuid: getPeerUUID(),
              peer_id: getPeerUUID(),
              peer_name: state.userName,
              peer_token: state.token,
              peer_presenter: false,
            },
          },
          async (response) => {
            if (response === "invalid") {
              commit(
                "system/SET_NOTIFICATION",
                {
                  type: "error",
                  name: "Что-то пошло не так",
                  description: "Неверный индификатор комнаты",
                },
                { root: true }
              );
              return;
            }
            if (response === "notAllowed") {
              console.log(
                "00-WARNING ----> Room is Unauthorized for current user, please provide a valid room name for this user"
              );
              return;
            }
            if (response === "unauthorized") {
              commit(
                "system/SET_NOTIFICATION",
                {
                  type: "error",
                  name: "Что-то пошло не так",
                  description: "Не удалось авторизоваться в комнате",
                },
                { root: true }
              );
              return;
            }
            if (response === "isLocked") {
              console.log(
                "00-WARNING ----> Room is Locked, Try to unlock by the password"
              );
              return;
            }
            if (response === "isLobby") {
              console.log(
                "00-WARNING ----> Room Lobby Enabled, Wait to confirm my join"
              );
              return;
            }
            if (response === "isBanned") {
              console.log("00-WARNING ----> You are Banned from the Room!");
              return;
            }

            document.getElementById("usedesk-messenger").style.display = "none";

            dispatch("getRoomInfo");

            await dispatch("initSockets");

            socket.emit("getRouterRtpCapabilities", {}, async (response) => {
              let routerRtpCapabilities = response;

              routerRtpCapabilities.headerExtensions =
                routerRtpCapabilities.headerExtensions.filter(
                  (ext) => ext.uri !== "urn:3gpp:video-orientation"
                );

              await loadDevice(routerRtpCapabilities).then(async (device) => {
                commit("SET_STATE", { state: "device", value: device });
                await dispatch("initTransfers");
              });
            });
          }
        );
      }
    );
  },

  async initTransfers({ state, commit, dispatch }) {
    await socket.emit(
      "createWebRtcTransport",
      {
        forceTcp: false,
        rtpCapabilities: state.device.rtpCapabilities,
      },
      (response) => {
        if (response.error) {
          return;
        }

        commit("SET_STATE", {
          state: "producerTransport",
          value: state.device.createSendTransport(response),
        });

        state.producerTransport.on(
          "connect",
          async ({ dtlsParameters }, callback) => {
            try {
              await socket.emit(
                "connectTransport",
                {
                  transport_id: state.producerTransport.id,
                  dtlsParameters,
                },
                () => {
                  callback();
                }
              );
            } catch (e) {
              console.log(e);
            }
          }
        );

        state.producerTransport.on(
          "produce",
          async ({ kind, appData, rtpParameters }, callback, errback) => {
            try {
              await socket.emit(
                "produce",
                {
                  producerTransportId: state.producerTransport.id,
                  kind,
                  appData,
                  rtpParameters,
                },
                (response) => {
                  callback({
                    id: response.producer_id,
                  });
                }
              );
            } catch (e) {
              console.log(e);
              errback(e);
            }
          }
        );

        state.producerTransport.on("connectionstatechange", async (resp) => {
          if (resp === "failed" && state.producerTransport) {
            state.producerTransport.close();
          }
        });

        state.producerTransport.on("icegatheringstatechange", async (resp) => {
          console.log(resp);
        });

        dispatch("startLocalMedia");
      }
    );

    socket.emit(
      "createWebRtcTransport",
      {
        forceTcp: false,
      },
      (response) => {
        if (response.error) {
          return;
        }

        commit("SET_STATE", {
          state: "consumerTransport",
          value: state.device.createRecvTransport(response),
        });

        socket.emit("getProducers");

        state.consumerTransport.on(
          "connect",
          async ({ dtlsParameters }, callback) => {
            try {
              await socket.emit(
                "connectTransport",
                {
                  transport_id: state.consumerTransport.id,
                  dtlsParameters,
                },
                () => {
                  callback();
                }
              );
            } catch (e) {
              console.log(e);
            }
          }
        );

        state.consumerTransport.on("connectionstatechange", (state) => {
          switch (state) {
            case "failed":
              state.consumerTransport.close();

              break;
          }
        });
      }
    );
  },

  initSockets({ dispatch }) {
    socket.on("message", (data) => {
      dispatch("messageHandle", data);
    });
    socket.on("refreshParticipantsCount", (data) => {
      dispatch("handleRefreshParticipantsCount", data);
    });
    socket.on("removeMe", (data) => {
      dispatch("handleRemoveMe", data);
    });
    socket.on("updatePeerInfo", (data) => {
      dispatch("handleUpdatePeerInfo", data);
      dispatch("getRoomInfo");
    });
    socket.on("user_joined", () => {
      dispatch("getRoomInfo");
    });

    socket.on("newProducers", (data) => {
      dispatch("handleNewProducers", data);
    });

    socket.on("consumerClosed", (data) => {
      dispatch("handleСonsumerClosed", data);
    });

    socket.on("setVideoOff", (data) => {
      dispatch("handleSetVideoOff", data);
    });

    socket.on("updateRoomModerator", (data) => {
      dispatch("handleUpdateRoom", data);
    });

    socket.on("peerAction", (data) => {
      dispatch("handlePeerAction", data);
    });

    socket.on("cmd", (data) => {
      dispatch("handleСmd", data);
    });
  },

  handleRefreshParticipantsCount({ commit }, data) {
    commit("SET_STATE", {
      state: "participantsCounts",
      value: data.peer_counts,
    });
  },

  handleRemoveMe({ dispatch }, data) {
    dispatch("handleRefreshParticipantsCount", data);
    dispatch("getRoomInfo");
  },

  async getRoomInfo({ commit, state, dispatch }) {
    socket.emit("getRoomInfo", {}, (room) => {
      commit("SET_MEETING_PARTICIPANTS", room.peers);
      commit("SET_STATE", {
        state: "roomInfo",
        value: room,
      });

      commit("SET_STATE", { state: "state", value: "room" });

      if (
        room.moderator.video_cant_unhide &&
        state.isCameraAllowed &&
        !state.cameraStartAdmin
      ) {
        dispatch("handleVideo");
      }

      if (
        room.moderator.audio_cant_unmute &&
        state.isAudioAllowed &&
        !state.audioStartAdmin
      ) {
        dispatch("handleAudio");
      }

      if (
        room.moderator.screen_cant_share &&
        state.isScreenBroadcastingAllowed
      ) {
        dispatch("stopScreenSharing");
      }
    });
  },

  async sendMessage({ commit, state }, message) {
    const data = {
      room_id: state.meeting.external_id,
      peer_id: getPeerUUID(),
      peer_name: state.userName,
      to_peer_id: "all",
      to_peer_name: "all",
      peer_msg: message,
    };

    socket.emit("message", data, (response) => {
      if (response.data === "ok") {
        commit("SET_MEETING_MESSAGE", data);
      }
    });
  },

  messageHandle({ commit }, data) {
    commit("SET_MEETING_MESSAGE", data);
  },

  async startLocalMedia({ state, dispatch }) {
    if (state.isAudioAllowed) {
      await dispatch("produce", {
        type: "audioType",
        device: state.selectedAudioDevice,
      });
    }

    if (state.isCameraAllowed) {
      await dispatch("produce", {
        type: "videoType",
        device: state.selectedCameraDevice,
      });
    }
  },

  async produce({ commit, state, dispatch }, payload) {
    let audio = false;
    switch (payload.type) {
      case "audioType":
        audio = true;
        break;
      case "videoType":
        commit("SET_STATE", { state: "isCameraAllowed", value: true });
        break;
      case "screenType":
        break;
      default:
        return;
    }

    let stream;
    try {
      if (payload.type === "videoType") {
        stream = state.cameraStream;
      }

      if (payload.type === "screenType") {
        stream = state.screenStream;
      }

      if (payload.type === "audioType") {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            deviceId: state.selectedAudioDevice,
          },
          video: false,
        });
      }

      const track = audio
        ? stream.getAudioTracks()[0]
        : stream.getVideoTracks()[0];

      let params = {
        track,
        appData: {
          mediaType: payload.type,
        },
      };

      const producer = await state.producerTransport.produce(params);

      if (payload.type === "screenType" && stream.getAudioTracks()[0]) {
        dispatch("produceScreenStreamAudio");
      }

      state.producers.set(producer.id, producer);
      state.producerLabel.set(payload.type, producer.id);

      producer.on("trackended", () => {
        dispatch("closeProducer", payload.type);
      });

      producer.on("transportclose", () => {
        dispatch("closeProducer", payload.type);
      });

      producer.on("close", () => {
        dispatch("closeProducer", payload.type);
      });

      return producer;
    } catch (err) {
      console.error("Produce error:", err);
    }
  },

  async produceScreenStreamAudio({ state, dispatch }) {
    try {
      const track = state.screenStream.getAudioTracks()[0];
      const params = {
        track,
        appData: {
          mediaType: "audioTab",
        },
      };

      const producer = await state.producerTransport.produce(params);

      state.producers.set(producer.id, producer);
      state.producerLabel.set("audioTab", producer.id);

      producer.on("trackended", () => {
        dispatch("closeProducer", "audioTab");
      });

      producer.on("transportclose", () => {
        dispatch("closeProducer", "audioTab");
      });

      producer.on("close", () => {
        dispatch("closeProducer", "audioTab");
      });
    } catch (err) {
      console.error("Produce error:", err);
    }
  },

  closeProducer({ state }, payload) {
    if (!state.producerLabel.has(payload)) {
      return console.warn(payload);
    }

    const producer_id = state.producerLabel.get(payload);
    const data = {
      peer_name: this.peer_name,
      producer_id: producer_id,
      type: payload,
      status: false,
    };

    socket.emit("producerClosed", data);
    state.producers.get(producer_id).close();
    state.producers.delete(producer_id);
    state.producerLabel.delete(payload);
  },

  async handleNewProducers({ dispatch }, data) {
    if (data.length > 0) {
      for (let { producer_id, peer_name, peer_info, type } of data) {
        await dispatch("consume", { producer_id, peer_name, peer_info, type });
      }
    }
  },

  async consume({ dispatch }, data) {
    try {
      const { consumer, stream } = await dispatch("getConsumeStream", {
        producerId: data.producer_id,
        peer_id: data.peer_info.peer_id,
        type: data.type,
      });

      try {
        socket.emit(
          "resumeConsumer",
          { consumer_id: consumer.id },
          (response) => {
            console.log("Consumer resumed", response);
          }
        );
      } catch (error) {
        console.error("Error resuming consumer", error);
      }

      consumer.on("trackended", () => {
        // this.removeConsumer(consumer.id, consumer.kind);
      });

      consumer.on("transportclose", () => {
        // this.removeConsumer(consumer.id, consumer.kind);
      });

      dispatch("handleConsumer", {
        consumerId: consumer.id,
        type: data.type,
        stream: stream,
        peer_name: data.peer_info.peer_name,
        peer_info: data.peer_info,
        peer_uuid: data.peer_info.peer_uuid,
      });
      if (state.participants) {
        dispatch("handleUpdatePeerInfo", {
          type: "video",
          peer_id: data.peer_info.peer_id,
          status: true,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },

  async getConsumeStream({ state }, payload) {
    const { rtpCapabilities } = state.device;
    const producerId = payload.producerId;
    const consumerTransportId = state.consumerTransport.id;

    return new Promise((resolve) => {
      socket.emit(
        "consume",
        {
          rtpCapabilities,
          producerId,
          consumerTransportId,
        },
        (response) => {
          const { id, kind, rtpParameters } = response;
          const streamId =
            payload.peer_id +
            (payload.type === "screen" ? "-screen-sharing" : "-mic-webcam");

          state.consumerTransport
            .consume({
              id,
              producerId,
              kind,
              rtpParameters,
              streamId,
            })
            .then((consumer) => {
              const stream = new MediaStream();
              stream.addTrack(consumer.track);

              resolve({
                consumer,
                stream,
                kind,
              });
            });
        }
      );
    });
  },

  async handleConsumer({ commit }, data) {
    commit("ADD_MEET_PRODUCER", data);
  },

  handleСonsumerClosed({ commit }, data) {
    commit("DELETE_MEET_PRODUCER", data);
  },

  handleUpdatePeerInfo({ commit }, data) {
    commit("UPDATE_PEER_INFO", data);
  },

  handleSetVideoOff({ commit }, data) {
    commit("UPDATE_PEER_INFO", {
      peer_id: data.peer_id,
      status: false,
      type: "video",
    });
  },

  clearMeeting({ commit }) {
    commit("CLEAR_MEETING");
  },

  handleUpdateRoom({ commit, state, dispatch }, data) {
    commit("UPDATE_ROOM_SETTINGS", data);

    if (
      data.type === "video_cant_unhide" &&
      data.status === true &&
      state.isCameraAllowed
    ) {
      dispatch("handleVideo");
    }

    if (
      data.type === "audio_cant_unmute" &&
      data.status === true &&
      state.isAudioAllowed
    ) {
      dispatch("handleAudio");
    }

    if (
      data.type === "screen_cant_share" &&
      data.status === true &&
      state.isScreenBroadcastingAllowed
    ) {
      dispatch("stopScreenSharing");
    }
  },

  handlePeerAction({ state, dispatch, commit }, data) {
    if (data.action === "mute") {
      if (state.isAudioAllowed) {
        dispatch("handleAudio");
      }
    }

    if (data.action === "unmute") {
      if (!state.isAudioAllowed) {
        commit("SET_STATE", { state: "audioStartAdmin", value: true });
        commit("SET_STATE", { state: "showMicrophoneModal", value: true });
      }
    }

    if (data.action === "hide") {
      if (state.isCameraAllowed) {
        dispatch("handleVideo");
      }
    }

    if (data.action === "unhide") {
      if (!state.isCameraAllowed) {
        commit("SET_STATE", { state: "showCameraModal", value: true });
        commit("SET_STATE", { state: "cameraStartAdmin", value: true });
      }
    }

    if (data.action === "stop") {
      if (state.isScreenBroadcastingAllowed) {
        dispatch("stopScreenSharing");
      }
    }
  },

  async handleСmd({ commit, dispatch, state }, data) {
    if (data.type === "ejectAll") {
      await state.consumerTransport.close();
      await socket.off("disconnect", () => {});
      await socket.off("newProducers", () => {});
      await socket.off("consumerClosed", () => {});
      await dispatch("loadMeetingInfo", state.meeting.id).then(() => {
        commit("SET_STATE", { state: "state", value: "ended" });
      });
    }
  },

  async exitMeetingRoom({ commit, state }) {
    socket.emit("exitRoom", {}, () => {});
    state.consumerTransport.close();
    socket.off("disconnect", {}, () => {});
    socket.off("newProducers", {}, () => {});
    socket.off("consumerClosed", {}, () => {});
    commit("SET_STATE", { state: "state", value: "exit" });
    commit("CLEAR_MEETING_ON_EXIT");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
